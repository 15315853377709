import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

export default function DeleteRecordButton({ onClick }: any) {
  return (
    <Tooltip title="Eliminar">
      <DeleteOutlined
        style={{ color: 'red', fontSize: 18 }}
        onClick={onClick}
      />
    </Tooltip>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { theme, Tooltip } from 'antd';

const EditRecordButton = ({ record, link }) => {
  const { token } = theme.useToken();
  return (
    <Tooltip title="Editar">
      <Link
        to={{
          pathname: `${link}/${record.id ? record.id : record.key}`,
          state: { record },
        }}
      >
        <EditOutlined style={{ color: token.colorPrimary, fontSize: 18 }} />
      </Link>
    </Tooltip>
  );
};

EditRecordButton.propTypes = {
  record: PropTypes.shape.isRequired,
  link: PropTypes.string.isRequired,
};

export default EditRecordButton;
